.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrCode {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.title {
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.qrLink {
  display: block;
  margin: 24px 0;

  p {
    margin: 0;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.qrForm {
  margin-top: 0;

  @media screen and (min-width: 1024px) {
    margin-top: 24px;
  }
}

.qrCodeLabel {
  font-size: 12px;
  color: var(--palette-text-secondary);
  text-align: center;
}

.link {
  margin: 0 6px;
  color: #000;
  font-weight: 600;
}

.button {
  padding: 12px;
}

.input {
  height: 42px;
}
