.container {
  display: flex;
  overflow: auto;
  align-items: center;

  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  background: var(--palette-default);
}

/* Logo */

.logo {
  position: absolute;
  z-index: 15;
  top: 40px;
  left: 40px;

  display: flex;
  align-items: center;
}

.logo .icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin-right: 12px;
  padding: 9px;

  border-radius: 12px;
  background: var(--palette-primary);
}

.logoText {
  color: var(--palette-default);

  font-size: 16px;
}

/*Left side*/

.sidebar {
  --sidebar-color: #142448;
  position: relative;

  width: 50%;
  height: 100vh;

  background: var(--sidebar-color);
}

.sidebar .gradientBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0.5;
  background: radial-gradient(202.79% 130.66% at -9.92% 127.34%, #2d2270 37.55%, #2e79be 79.89%);
  mix-blend-mode: overlay;
}

.sidebar .sidebarBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: url("assets/images/login-messages-bg.svg") repeat;
  background-size: auto;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 59px;
  padding-left: 36px;
}

/* Info */

.info {
  display: flex;

  color: var(--palette-default);
}

.option {
  margin-right: 80px;

  color: var(--palette-default);

  font-size: 12px;
}

/* Right Side Form */

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: 100vh;
}

.formTitle {
  margin-bottom: 8px;

  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.formSubTitle {
  margin-bottom: 24px;

  font-weight: normal;
  font-style: normal;
}

.loginForm form {
  width: calc(100% - 120px);
  max-width: 563px;
}

.loginForm .forgotPassword {
  margin-top: -10px;
  margin-bottom: 24px;
  margin-left: auto;

  cursor: pointer;
  text-align: right;

  color: var(--palette-primary);
}

/* Submit */

.btn {
  width: 95px;
  margin: 0 0 0 auto;
}

@media (max-width: 992px) {
  .sidebar {
    display: none;
  }

  .loginForm {
    width: 100%;
  }

  .loginForm form {
    width: calc(100% - 80px);
  }

  .logoText {
    color: var(--palette-text-default);
  }
}

@media (max-width: 500px) {
}
