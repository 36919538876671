.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.loader {
  position: relative;
  margin-top: 24px;
}

.errorModal {
  padding: 24px;
}

.errorModal h1 {
  margin: 12px 0 6px 0;
}

.errorModal p {
  font-size: 14px;
  margin: 0;
  color: var(--palette-text-secondary);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
}

.buttonWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
