.wrapper {
  width: 100%;
  border-top: 1px solid #e1e1e1;
  padding-top: 24px;
}

.title {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 20px;
}

.description {
  font-size: 14px;
  color: var(--palette-text-secondary);
  margin-top: 0;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin: 24px 0;

  p {
    margin: 0;
    font-weight: 600;
    color: var(--palette-error);
  }

  span {
    font-size: 12px;
    color: var(--palette-text-secondary);
  }

  svg {
    width: 24px;
    height: 24px;
    color: var(--palette-error);
  }
}

.enabled {
  @extend .row;

  p {
    color: var(--palette-success);
  }

  svg {
    color: var(--palette-success);
  }
}

.disabled {
  @extend .row;

  p {
    color: var(--palette-error);
  }

  svg {
    color: var(--palette-error);
  }
}
