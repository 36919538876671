.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
}

.input {
  width: 50px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  font-size: 30px;
  text-align: center;

  &:focus {
    outline: 1px solid #ccc;
  }
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--palette-error);
  margin-top: 12px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--palette-text-secondary);
  margin-top: 12px;
}
