.virtualMfaForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caption {
  margin-bottom: 24px;
  color: var(--palette-text-secondary);

  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.goBack {
  margin-top: 12px;

  svg {
    color: var(--palette-text-secondary);
  }
}
